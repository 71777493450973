.vertical-wrapper {
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.auth-container > h2 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  text-align: center;
}

.ui.container.auth-container {
  box-shadow: 0 0 2px rgba(77, 100, 255, 0.05), 0 1px 3px rgba(24, 35, 55, 0.15);
  border-radius: 4px;
  background: white;
  padding: 2rem;
  max-width: 380px !important;
}

.ui.primary.button {
  background-color: #4d64ff !important;
  margin-bottom: 0.5rem;
}

a {
  font-size: 1rem;
  color: #5a6270 !important;
}

.ui.card {
  width: auto !important;
}
